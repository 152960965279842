import { okFetch } from '../util/http';
import * as QS from '../util/qs';

/**
 * Create an API operation.
 *
 * Creates an operation by taking descriptors for `resource` and `searchParams`
 * that can be either fixed strings or functions that generate strings from the
 * props provided to the operation.
 *
 * An API 'operation' is a function that accepts a base URL and a parameters
 * object, and returns a promise of data.
 *
 * @param {string|(params: object) => string} resource
 * @param {object|(params: object) => object} [searchParams]
 *
 * @example
 *
 *   const getUserInfo = createOperation('user/info');
 *   getUserInfo('/api/v1'); // fetches '/api/v1/user/info'
 *
 *   const getUserById = createOperation(({ id }) => `users/${id}`);
 *   getUserById('/api', { id: 1 }) // fetches '/api/users/1'
 *
 *   const search = createOperation('search', ({ query }) => ({ query }));
 *   getUserById('/api', { query: 'foo' }) // fetches '/api/search?query=foo'
 */
const createOperation = (resource, searchParams, method = 'GET') => (baseUrl, params, fetchOptions) => {
  if (method === 'GET') {
    const applyParams = scalarOrFn =>
      typeof scalarOrFn === 'function' ? scalarOrFn(params) : scalarOrFn;

    let url = `${baseUrl}/${applyParams(resource)}`;
    const queryString = QS.stringify(applyParams(searchParams));
    if (queryString.length > 0) {
      url += `?${queryString}`;
    }
    return okFetch(url, fetchOptions);
  } else {
    let url = `${baseUrl}/${resource}`;
    return okFetch(url, {
      method,
      headers: {
        ...fetchOptions.headers,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({...params}),
    });
  };
}

export default createOperation;
