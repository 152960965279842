import createOperation from './createOperation';

export const getKpis = createOperation('kpis');

export const getLocations = createOperation('locations');

export const getMetrics = createOperation(
  'metrics',
  ({ location, period, channel, businessModel }) => ({
    location,
    period,
    channel,
    businessModel,
  })
);

export const getMetricsForTimeframe = createOperation(
  'bq_metrics',
  ({ location, channel, businessModel, endDate, startDate }) => ({
    location,
    channel,
    businessModel,
    start_date: startDate, 
    end_data : endDate
  }), "POST"
);

export const getRealTimeMetrics = createOperation(
  'todays_metrics',
  ({ location, period, channel, businessModel }) => ({
    location,
    period,
    channel,
    businessModel,
  })
);

export const getPeriods = createOperation('periods');

export const getProductData = createOperation(
  'products',
  ({ location, period, channel, businessModel }) => ({
    location,
    period,
    channel,
    businessModel,
  })
);

export const getStockData = createOperation(
  'sales',
  ({ location, period, channel, businessModel }) => ({
    location,
    period,
    channel,
    businessModel,
  })
);
